import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { withStyles, Button } from 'altreidsds';
import { AppLogger } from '@just-ai/logger';

import * as socketActions from 'middleware/sockets/socketActions';
import {
  getCurrentUser,
  getPaymentData,
  logout,
  sendFeedback,
  setUserLang,
  getGptStatus,
} from 'actions/currentUser.actions';
import { addSnackbar, changeSnackbar, dismissGlobalAlert } from 'actions/snackbars.actions';
import { createProject, loadProjects, loadUniqClients, removeEditableProject } from 'actions/botprojects.actions';
import {
  changeAccount,
  getCurrentLoginAccount,
  loginToAccount,
  logoutFromAccount,
  setCurrentLoginAccount,
} from 'actions/accounts.actions';

import Header from 'components/Header';
import MobileHeader from 'components/Header/NewHeaderComponents/MobileHeader';
import Sidebar from 'components/Sidebar';
import SnackbarContainer from 'components/SnackbarContainer';

import FeedbackModal from 'views/Templates/FeedbackModal';
import GlobalAlertModal from 'views/Templates/GlobalAlertModal';
import Spinner from 'components/Spinner';

import { t } from 'localization';
import history from 'appHistory';
import { setCurrentProject } from 'actions/currentProject.actions';
import * as loginActions from 'actions/currentUser.actions';

import { getChannelsList } from 'actions/channels.actions';

import DemoBlocked from 'components/DemoBlocked';
import queryString from 'query-string';
import BotWillCallModal from 'components/BotWillCallModal';
import ConfirmNumberModal from 'components/ConfirmNumber/ConfirmNumberModal';
import isAccess, { isIncluded } from 'isAccessFunction';

import styles from './styles';
import MobileWarningModal from 'views/MobileWarningModal';
import { WSContextProvider } from 'contexts/WebSocketContext';
import NotificationController from './NotificationController';
import ErrorPage from 'views/ErrorPage';
import { checkIsPageWithOldHeader, checkOnlyRussia, isDev } from '../../pipes/pureFunctions';
import chatSupportController from 'helpers/chatSupportController';
import { ErrorModal } from './ErrorModal';
import { isEuroInstance, isReporterEnabled } from '../../isAccessFunction';
import { redirectToPaymentSystem } from 'pipes/paymentFunction';
import * as TemplatesAction from '../../actions/templates.actions';
import { TestWidgetContext } from '../../modules/TestWidget/TestWidgetContext';
import ProjectService from '../../modules/ProjectsApi/service/ProjectService';
import { VerificationModal } from '../../components/VerifyAccount/VerificationModal';
import { BASE_WIZARD_PATH_STRING } from '../../modules/TemplatesWizard/shared/consts';
import { AppContextProvider } from '../../contexts/AppContext';
import stls from './styles.module.scss';

// import OAuthProvider from '@just-ai/just-ui/dist/Oa';

const PAGES_WITH_MOBILE_VERSION = ['/', '/callsbots', '/prices', '/contract'];

class Full extends Component {
  sockets = false;

  showBlocked = false;
  demoBlockedInstance = null;
  timeout = null;

  state = {
    load: false,
    open: false,
    openFeedbackModal: false,
    openMobileWarningModal: false,
    hasError: false,
    chatWidgetError: null,
    BotProjectsService: null,
  };

  contentHeight = 1080;

  componentWillMount() {
    const {
      currentUser,
      loggedInAccount,
      getCurrentLoginAccount,
      botActions,
      loginActions,
      templatesActions,
      location,
      addSnackbar,
      userNeedToSpecifyCountryIsoCode,
    } = this.props;
    const parsedLocationSearch = queryString.parse(location.search);
    const { autoLoginToken, payAdditionalPackage } = parsedLocationSearch;

    const load = async () => {
      if (!currentUser || autoLoginToken) {
        if (autoLoginToken && payAdditionalPackage) {
          window.location.href = `${window.location.origin}/c/login?token=${autoLoginToken}&redirectUrl=${window.location.origin}?payAdditionalPackage=${payAdditionalPackage}`;
        }

        try {
          const response = await loginActions.login(null, autoLoginToken);
          if (response.value.data.internal || !response.value.data.account || !response.value.data.accountOwner) {
            return (window.location.href = '/c/account-or-user-unsupported');
          }
          if (process.env.NODE_ENV !== 'development') {
            window.dataLayer.push({ userId: `${response.value.data.id}` });
          }
          if (payAdditionalPackage) {
            await this.payAdditionalPackage(payAdditionalPackage);
          }
        } catch (e) {
          const error = e.response?.data?.errorCode;
          if (error?.includes('autoLoginToken')) {
            history.push({ search: `errorCode=${e.response.data.errorCode}` });
            addSnackbar(t(`Login:BE-error ${error}`), 'error');
          }
        }
        await loginActions.getUserAccount().catch(AppLogger.createErrorHandler('getUserAccount'));
        if (this.props.currentUser?.account?.id) {
          await loginActions
            .getManualControlInfo(this.props.currentUser.account.id)
            .catch(AppLogger.createErrorHandler('getManualControlInfo'));
          if (!isAccess('mlp_billing')) this.props.getGptStatus(this.props.currentUser.account.id);
          await loginActions
            .getUserSubscription(this.props.currentUser?.account?.id)
            .catch(AppLogger.createErrorHandler('getUserSubscription'));
        }
        loginActions.getCallsAvailability().catch(AppLogger.createErrorHandler('getCallsAvailability'));
        loginActions.checkUserPhoneCountryCode().catch(AppLogger.createErrorHandler('checkUserPhoneCountryCode'));
      } else if (currentUser && !currentUser.tariff) {
        await loginActions.getUserAccount().catch(AppLogger.createErrorHandler('getUserAccount'));
        await loginActions
          .getManualControlInfo(currentUser.account.id)
          .catch(AppLogger.createErrorHandler('getManualControlInfo'));
        await loginActions
          .getUserSubscription(currentUser.account.id)
          .catch(AppLogger.createErrorHandler('getUserSubscription'));
        if (!isAccess('mlp_billing')) this.props.getGptStatus(this.props.currentUser.account.id);
        loginActions.getCallsAvailability().catch(AppLogger.createErrorHandler('getCallsAvailability'));
        loginActions.checkUserPhoneCountryCode().catch(AppLogger.createErrorHandler('checkUserPhoneCountryCode'));
      }

      if (this.props.currentUser && !this.props.currentUser.accountOwner) {
        window.location.href = '/c/account-or-user-unsupported';
      }

      if (
        this.props.currentUser &&
        !this.props.currentUser.emailVerified &&
        this.props.currentUser.emailVerificationRequired &&
        !isDev()
      ) {
        window.location.href = '/c/verify-email';
      }

      if (
        userNeedToSpecifyCountryIsoCode &&
        this.props.currentUser &&
        !this.props.currentUser.tariff?.countryIsoCode &&
        !isDev()
      ) {
        window.location.href = '/c/select-country';
      }

      if (payAdditionalPackage && !autoLoginToken) {
        await this.payAdditionalPackage(payAdditionalPackage).catch(
          AppLogger.createErrorHandler('payAdditionalPackage')
        );
      }

      templatesActions.init();

      if (!loggedInAccount) {
        await getCurrentLoginAccount();
      }

      if (!this.props.currentUser?.emailVerified && this.props.currentUser?.newRegistration) {
        return history.push('/verifyEmail');
      }

      this.setState({
        BotProjectsService: new ProjectService(this.props.currentUser.account.id || -1),
      });
      await botActions.loadProjects(() => this.state.BotProjectsService.getProjects());

      if (this.props.location.pathname !== '/') {
        await this.checkCookies();
      }
    };

    load()
      .catch(AppLogger.createErrorHandler('ZenflowMainLoad'))
      .finally(() => this.setState({ load: true }));

    this.initChatSupport();
  }
  initChatSupport = () => {
    const { currentUser, appConfig } = this.props;
    if (Boolean(appConfig.zenflow.chatSupport) === false) return;
    try {
      chatSupportController.init(() => {}, currentUser.email);
      chatSupportController.show();
    } catch (e) {
      this.supportTimer = setTimeout(this.initChatSupport, 500);
    }
  };

  componentDidMount() {
    this.getContentHeight();
    window.addEventListener('resize', this.getContentHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getContentHeight);
    clearTimeout(this.supportTimer);
  }

  clearChatWidgetError = () => {
    this.setState({ chatWidgetError: null });
  };

  setChatWidgetError = error => {
    this.setState({ chatWidgetError: error });
  };

  setChatWidgetHandler = () => {
    if (window.JustWidget) {
      window.JustWidget.showErrorOverlay = this.setChatWidgetError;
    }
  };

  resetCurrentProject = async () => {
    await this.props.botActions.loadProjects(() => this.state.BotProjectsService.getProjects());
    if (isReporterEnabled()) this.props.botActions.loadUniqClients(this.props.currentUser.account.id);
    if (this.props.location.pathname !== '/') {
      await this.checkCookies();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      UI,
      location: { pathname },
    } = this.props;

    if (
      UI.isMobile &&
      this.checkMobileAvailablePage(prevProps.location.pathname) &&
      this.checkMobileAvailablePage(pathname) === false
    ) {
      this.setState({ openMobileWarningModal: true });
    }

    // fix setCurrentProject if history.goBack from prices or personal page to scenario page
    if (
      ['/prices', '/personal'].includes(prevProps.location.pathname) &&
      this.props.location.pathname.includes('/scenario') &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.resetCurrentProject();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      appConfig,
      location: { pathname },
    } = this.props;

    if (history.action === 'POP' && pathname !== nextProps.location.pathname) {
      this.checkCookies();
    }

    let docTitle = Boolean(appConfig.zenflow)
      ? `${appConfig.zenflow.companyName} ${appConfig.zenflow.productName}`
      : t('aimy');

    if (isEuroInstance()) {
      docTitle = t('tovi');
    }

    const projectName = nextProps.botList.find(item => item?.shortName === nextProps?.currentProject)?.name;
    document.title = projectName ? `${docTitle} - ${projectName}` : docTitle;
  }

  getContentHeight = () => {
    this.contentHeight = window.innerHeight - 50;
    this.forceUpdate();
  };

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
    document.body.classList.toggle('sidebar-open');
  };

  toggleFeedbackPopup = () => this.setState({ openFeedbackModal: !this.state.openFeedbackModal });

  checkCookies = () => {
    const { botList, setCurrentProject } = this.props;
    const { location } = history.action === 'POP' ? history : this.props;
    const { pathname } = location;
    let split = pathname.split('/');
    let prShortName = '';

    if (pathname === '/loginWithAmazon') {
      let parsedLocationSearch = queryString.parse(location.search);
      let parsedLocationSearchState = queryString.parse(parsedLocationSearch.state);
      prShortName = parsedLocationSearchState.pathname.split('/')[2];
      if (Boolean(prShortName)) {
        let currentBot = botList.find(item => item.shortName === prShortName);
        if (currentBot) {
          return setCurrentProject(prShortName, currentBot);
        }
      }
    } else if (!(pathname.startsWith('/subscription/') || pathname.startsWith('/startwizard'))) {
      //TODO fix this crutch crutch
      if (Boolean(split[2])) {
        prShortName = split[2];
        let currentBot = botList.find(item => item.shortName === prShortName);
        if (currentBot) {
          return setCurrentProject(prShortName, currentBot);
        }
        if (
          !['templatewizard', 'incomingtemplatewizard', 'bottemplatewizard', BASE_WIZARD_PATH_STRING].includes(split[1])
        ) {
          history.push('/404');
        }
      }
    }
  };

  logoutAction = async () => {
    history.push('/logout');
  };

  clearError = () => this.setState({ hasError: false });

  hideMobileWarningModal = () => this.setState({ openMobileWarningModal: false });

  checkMobileAvailablePage = (pathname = '') => {
    return PAGES_WITH_MOBILE_VERSION.includes(pathname) || pathname.includes('/chatwidgetEditor');
  };

  withoutScrollbar = () => {
    const {
      location: { pathname },
    } = this.props;

    return Boolean(
      pathname.includes('/scenario/') ||
        pathname === '/' ||
        pathname === '/callsbots' ||
        pathname.includes('/statistics/') ||
        (pathname.includes('/dialogs/') && isReporterEnabled())
    );
  };

  renderMainContent = () => {
    const {
      classes,
      location: { pathname },
      children,
      UI,
    } = this.props;

    const isMobile = UI.isMobile && UI.forceDesktopView === false && this.checkMobileAvailablePage(pathname);

    const oldHeaderPage = checkIsPageWithOldHeader(pathname);

    const content = (
      <main
        id='main_content'
        className={classNames(isMobile ? classes.mobileContent : classes.content, {
          [classes.contentShift]: this.state.open,
          [classes.withoutPadding]: pathname.includes('templatewizard/'),
          [classes.withoutPaddingNormalWidth]: pathname.includes('mailingtasks/'),
          [classes.faqContainer]: pathname.includes('faq/') || pathname.includes('faq-template/'),
          [classes.withoutBottomPaddingToo]: pathname === '/' || pathname === '/callsbots',
          fullSizePage: !oldHeaderPage,
        })}
      >
        {children}
      </main>
    );

    if (this.withoutScrollbar()) return content;

    return (
      <div
        className={classNames(
          oldHeaderPage && !isMobile && classes.scrollbarContainer,
          classes.mainContent,
          stls.scrollbar,
          {
            [classes.fullHeight]: pathname.includes('faq/') || pathname.includes('faq-template/'),
          }
        )}
      >
        {content}
      </div>
    );
  };

  payAdditionalPackage = async payAdditionalPackage => {
    const { loginActions } = this.props;
    try {
      const {
        value: { data },
      } = await loginActions.buyAdditionalPackages([payAdditionalPackage], window.location.origin + '/personal?tab=0');
      if (data.changed && !data.paymentId && !data.redirectUrl) {
        if (data.changed) {
          history.push('/personal?tab=0');
        }
      } else {
        redirectToPaymentSystem(data, this.props.currentUser);
      }
    } catch (e) {
      const error = e?.response?.data?.error?.errorCode;
      if (error) {
        addSnackbar(t(`Payment:BE-error ${error}`), 'error');
      }
    }
  };

  render() {
    const {
      classes,
      currentUser,
      globalAlert,
      sendFeedback,
      addSnackbar,
      dismissGlobalAlert,
      appConfig,
      UI,
      language,
      location,
      currentProject,
      setUserLang,
      setCurrentProject,
      cloudDomains,
      ccSessionsAreEnabled,
      universalLoginMenuEnabled,
    } = this.props;

    const { pathname } = location;

    const isMobile = UI.isMobile && UI.forceDesktopView === false && this.checkMobileAvailablePage(pathname);

    const { load, openMobileWarningModal, hasError } = this.state;

    if (hasError)
      return (
        <ErrorPage
          logout={this.logoutAction}
          clearError={this.clearError}
          ccSessionsAreEnabled={ccSessionsAreEnabled}
        />
      );

    if (Boolean(load) === false)
      return (
        <>
          <style dangerouslySetInnerHTML={{ __html: `#launcher { display: none !important; }` }} />
          <Spinner />
        </>
      );

    const showSidebar = [
      '/bot_dashboard/',
      '/scenario/',
      '/entities/',
      '/channels/',
      '/knowledge/',
      '/dialogs/',
      '/settings/',
      '/chatwidgetEditor/',
      '/voicechannels/',
      '/skills-post-setup/',
      '/faq/',
      '/faq-template/',
      '/statistics/',
      '/mailingtasks/',
    ].some(p => pathname.includes(p));

    const hiddenHeader = pathname.includes('/bot_dashboard/');

    return (
      <div
        className={classNames(
          classes.appFrame,
          'full-main',
          isMobile === false && showSidebar && (hiddenHeader ? 'withSidebarWithoutHeader' : 'withSidebar')
        )}
      >
        <AppContextProvider>
          <TestWidgetContext.Provider
            value={{
              setHandler: this.setChatWidgetHandler,
              clearError: this.clearChatWidgetError,
            }}
          >
            <WSContextProvider>
              {appConfig.fetched && isIncluded(['zenflow_billing_feature']) && (
                <DemoBlocked
                  isMobile={isMobile}
                  language={language}
                  onRef={i => (this.demoBlockedInstance = i)}
                  data-test-id='DemoBlocked'
                  classes={classes}
                />
              )}
              {isMobile && (
                <MobileHeader
                  data-test-id='MobileHeader'
                  open={this.state.open}
                  appConfig={appConfig}
                  location={location}
                  currentProject={currentProject}
                  setUserLang={setUserLang}
                  language={language}
                  logout={this.logoutAction}
                  currentUser={currentUser}
                  setCurrentProject={setCurrentProject}
                  isOrderBotEnabled={pathname !== '/botDevelopmentRequest' && !checkOnlyRussia(currentUser)}
                  ccSessionsAreEnabled={ccSessionsAreEnabled}
                />
              )}
              {isMobile === false && !hiddenHeader && (
                <Header
                  data-test-id='Header'
                  open={this.state.open}
                  appConfig={appConfig}
                  botList={this.props.botList}
                  location={location}
                  currentProject={currentProject}
                  setUserLang={setUserLang}
                  language={language}
                  currentUser={currentUser}
                  setCurrentProject={setCurrentProject}
                  addSnackbar={addSnackbar}
                  logout={this.logoutAction}
                  cloudDomains={cloudDomains}
                  universalLoginMenuEnabled={universalLoginMenuEnabled}
                />
              )}
              <ConfirmNumberModal />
              {Boolean(currentUser?.account) && (
                <VerificationModal accountId={currentUser.account.id} currentUser={currentUser} />
              )}
              {this.state.chatWidgetError && (
                <ErrorModal text={this.state.chatWidgetError} toggle={this.clearChatWidgetError} />
              )}
              <BotWillCallModal currentUser={currentUser} />
              {Boolean(currentUser) ? (
                <FeedbackModal
                  data-test-id='FeedbackModal'
                  key='feedback-modal'
                  addSnackbar={addSnackbar}
                  sendFeedback={sendFeedback}
                  currentUser={currentUser}
                  togglePopup={this.toggleFeedbackPopup}
                  open={this.state.openFeedbackModal}
                  onClose={this.toggleFeedbackPopup}
                />
              ) : null}
              {isMobile === false && showSidebar && (
                <Sidebar
                  data-test-id='Sidebar'
                  open={this.state.open}
                  toggleDrawer={this.toggleDrawer}
                  togglePopup={this.togglePopup}
                  toggleFeedbackPopup={this.toggleFeedbackPopup}
                  logout={this.logoutAction}
                  currentUser={currentUser}
                />
              )}
              <MobileWarningModal
                onClose={this.hideMobileWarningModal}
                pathname={pathname}
                open={openMobileWarningModal}
              />
              {this.renderMainContent()}

              {Boolean(globalAlert) ? (
                <GlobalAlertModal
                  data-test-id='GlobalAlertModal'
                  open={Boolean(globalAlert)}
                  globalAlert={globalAlert}
                  dismissGlobalAlert={dismissGlobalAlert}
                />
              ) : null}
              <SnackbarContainer className={classNames(classes.snackbar, this.state.open && classes.snackbarShift)} />
              {UI.isMobile && this.checkMobileAvailablePage(pathname) === false && (
                <div className={classes.returnToMobileButton}>
                  <Button
                    variant='contained'
                    onClick={() => history.push('/dashboard')}
                    color='warning'
                    data-test-id='GoToDashboardButton'
                  >
                    {t('ContractRequest Go to my dashboard')}
                  </Button>
                </div>
              )}

              <NotificationController />
            </WSContextProvider>
          </TestWidgetContext.Provider>
        </AppContextProvider>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  socketActions: bindActionCreators(socketActions, dispatch),
  setCurrentProject: bindActionCreators(setCurrentProject, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
  getPaymentData: bindActionCreators(getPaymentData, dispatch),
  sendFeedback: bindActionCreators(sendFeedback, dispatch),
  logout: bindActionCreators(logout, dispatch),
  addSnackbar: bindActionCreators(addSnackbar, dispatch),
  changeSnackbar: bindActionCreators(changeSnackbar, dispatch),
  dismissGlobalAlert: bindActionCreators(dismissGlobalAlert, dispatch),
  logoutFromAccount: bindActionCreators(logoutFromAccount, dispatch),
  setCurrentLoginAccount: bindActionCreators(setCurrentLoginAccount, dispatch),
  getGptStatus: bindActionCreators(getGptStatus, dispatch),
  getCurrentLoginAccount: bindActionCreators(getCurrentLoginAccount, dispatch),
  loginToAccount: bindActionCreators(loginToAccount, dispatch),
  changeAccount: bindActionCreators(changeAccount, dispatch),
  setUserLang: bindActionCreators(setUserLang, dispatch),
  getChannelsList: bindActionCreators(getChannelsList, dispatch),
  botActions: bindActionCreators(
    {
      removeEditableProject,
      createProject,
      loadProjects,
      loadUniqClients,
    },
    dispatch
  ),
  loginActions: bindActionCreators(
    {
      ...loginActions,
    },
    dispatch
  ),
  templatesActions: bindActionCreators(
    {
      ...TemplatesAction,
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
    language: state.CurrentUserReducer.language,
    features: state.CurrentUserReducer.features,
    loggedInAccount: state.AccountsReducer.loggedInAccount,
    accounts: state.AccountsReducer.accounts,
    fetching: state.BotProjectsReducer.fetching,
    currentProject: state.CurrentProjectsReducer.currentProject,
    botList: state.BotProjectsReducer.botList,
    globalAlert: state.SnackbarsReducer.globalAlert,
    channels: state.ChannelsReducer.channels,
    templatesList: state.TemplatesReducer.templatesList,
    UI: state.UIReducer,
    currentBot: state.CurrentProjectsReducer.currentBot,
    appConfig: {
      fetching: state.AppConfigReducer.fetching,
      fetched: state.AppConfigReducer.fetched,
      zenflow: state.AppConfigReducer.zenflow,
    },
    cloudDomains: state.AppConfigReducer.cloudDomains,
    ccSessionsAreEnabled: state.AppConfigReducer.ccSessionsAreEnabled,
    universalLoginMenuEnabled: state.AppConfigReducer.universalLoginMenuEnabled,
    userNeedToSpecifyCountryIsoCode: state.AppConfigReducer.userNeedToSpecifyCountryIsoCode,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Full)));
