import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Drawer, Menu, MenuItem, ListItem, List, ListItemIcon, ListItemText } from 'altreidsds';

import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from 'altreidsds';

import AppHelpNavListItems from './SidebarItems/AppHelpNavListItems';

import { bindActionCreators } from 'redux';
import * as BotProjectsActions from '../../actions/botprojects.actions';
import { setCurrentProject } from '../../actions/currentProject.actions';
import { connect } from 'react-redux';
import BotNavListItem from './SidebarItems/BotNavListItem';

import AppIcon from '../../components/AppIcon';
import { Scrollbars } from 'react-custom-scrollbars';
import { checkTariffRestrictions as isUserWithoutTariff } from '../../pipes/functions';
import { GA } from '../../pipes/pureFunctions';
import { t } from '../../localization';
import history from '../../appHistory';
import { isIncluded } from '../../isAccessFunction';

import styles from './styles';

import chatSupportController from 'helpers/chatSupportController';
import { Icon } from '@just-ai/just-ui';

const GAEventProfileToS = () => GA('GAEvent', 'Sidebar_Menu_Select', 'opened', 'Profile_ToS');

class Sidebar extends Component {
  state = {
    editableBot: null,
    anchorEl: null,

    messages: [],
  };

  componentDidMount() {
    if (Boolean(this.props.appConfig.zenflow.chatSupport)) {
      this.supportAddOnMessageListener();
      chatSupportController.close();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.supportTimer);
    if (Boolean(this.props.appConfig.zenflow.chatSupport)) {
      chatSupportController.show();
    }
  }

  supportAddOnMessageListener = () => {
    const { currentUser } = this.props;
    try {
      chatSupportController.init(this.receiveMessage, currentUser.email);
      chatSupportController.close();
    } catch (e) {
      this.supportTimer = setTimeout(this.supportAddOnMessageListener, 500);
    }
  };

  receiveMessage = message => {
    let mess = [...this.state.messages];
    mess.push(message);
    this.setState({ messages: mess });
  };

  toggleFeedbackPopup = e => {
    e.stopPropagation();
    e.preventDefault();
    GA('GAEvent', 'Sidebar_Menu_Select', 'opened', 'Support');
    chatSupportController.toggle(() => {
      this.setState({ messages: [] });
    });
  };

  handleMenu = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  renderMenuItem = ({ pathname, search, label, link, dataTestId, eventLabel }, index) => {
    const { classes } = this.props;
    return (
      <MenuItem
        key={index}
        classes={{ root: classes.menuItemRoot }}
        onClick={() => {
          this.handleClose();
          pathname && history.push({ pathname, search });
          GA('GAEvent', 'Sidebar_Menu_Select', 'opened', eventLabel);
        }}
        className='outsideAction'
        data-test-id={dataTestId}
      >
        {label ? t(label) : ''}
        {link ? link : null}
      </MenuItem>
    );
  };

  renderMenu = () => {
    const { classes, currentUser, logout, cloudDomains } = this.props;
    const { anchorEl } = this.state;

    const isTariffAvailable =
      Boolean(isUserWithoutTariff(currentUser) || !isIncluded(['zenflow_billing_feature'])) === false;
    const isTermLinkExist = t('ToS link').trim().length > 0;
    return (
      <Menu
        id='sidebar_person_pop'
        key='sidebar_person_pop'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
        PopoverClasses={{ paper: classes.popoverRoot }}
        className={classes.sidebarPersonPop}
        MenuListProps={{ classes: { padding: classes.menuListPadding }, 'data-test-id': 'Sidebar.Personal.List' }}
        data-test-id='Sidebar.Personal'
      >
        {Boolean(currentUser) && (
          <MenuItem
            classes={{ root: classes.menuItemRootName }}
            className='outsideAction'
            data-test-id='Sidebar.Personal.email'
          >
            {currentUser.email}
          </MenuItem>
        )}
        {[
          isTariffAvailable && {
            pathname: `/personal`,
            search: 'tab=0',
            label: 'Tariff management',
            dataTestId: 'Sidebar.Personal.tariff',
            eventLabel: 'Profile_Subscription',
          },
          isTariffAvailable && {
            pathname: `/personal`,
            search: 'tab=1',
            label: 'Payments',
            dataTestId: 'Sidebar.Personal.payments',
            eventLabel: 'Profile_Payment_History',
          },
          {
            link: (
              <a
                href={`${window.location.protocol}//${cloudDomains.cc?.domain}/c/profile`}
                color='inherit'
                data-test-id='Sidebar.Personal.accountSettings'
              >
                {t('Account settings')}
              </a>
            ),
            eventLabel: 'Profile_Account',
          },
          isIncluded(['zenflow_telephony_feature']) && {
            pathname: `/personal`,
            search: 'tab=4',
            label: 'TelephonySettings title',
            dataTestId: 'Sidebar.Personal.telephonySettings',
            eventLabel: 'Profile_Telephony',
          },
          isTermLinkExist &&
            !currentUser?.tariff?.manualControl && {
              link: (
                <a
                  href={t('ToS link')}
                  target='_blank'
                  rel='noopener noreferrer'
                  color='inherit'
                  data-test-id='Sidebar.Personal.ToSLink'
                  onClick={GAEventProfileToS}
                >
                  {t('Terms of use')}
                </a>
              ),
            },
        ]
          .filter(x => x)
          .map(this.renderMenuItem)}
        {Boolean(currentUser) && currentUser?.tariff?.manualControl && (
          <MenuItem
            classes={{ root: classes.menuItemRootName }}
            style={{ height: 'auto' }}
            className='outsideAction'
            data-test-id='Sidebar.Personal.email'
          >
            <span
              style={{ maxWidth: 220, lineHeight: '16px', whiteSpace: 'normal' }}
              dangerouslySetInnerHTML={{ __html: t('Sidebar: manual control user') }}
            />
          </MenuItem>
        )}
        <MenuItem classes={{ root: classes.menuItemRootSplitter }} />
        <MenuItem
          className='lastItem outsideAction'
          onClick={() => {
            logout();
            GA('GAEvent', 'Sidebar_Menu_Select', 'opened', 'Profile_Sign_Out');
          }}
          classes={{ root: classes.menuItemRoot }}
          data-test-id='Sidebar.Personal.logout'
        >
          {t('Sign out')}
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const {
      classes,
      open,
      currentUser,
      currentProject,
      appConfig,
      language,
      togglePopup,
      botList,
      anchorEl,
      currentBot,
      location,
    } = this.props;

    const { messages } = this.state;
    const currentBotName = currentProject;

    return [
      <Drawer
        key='sidebar-drawer'
        variant='permanent'
        id='sidebar'
        classes={{
          paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Grid
          container
          wrap='nowrap'
          id='Sidebar'
          spacing={8}
          direction='column'
          justify='space-between'
          alignItems='stretch'
          className={classes.container}
        >
          <Grid item>
            <div className={classes.drawerHeader}>
              <Grid spacing={24} container wrap='nowrap' justify='center' alignItems='center'>
                <Grid item data-test-id='callsbots'>
                  <Link to={currentBot?.botType === 'CALLS_BOT' ? '/callsbots' : '/'}>
                    <AppIcon />
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Scrollbars
            renderThumbVertical={props => <div {...props} className='visualEditor-thumb-vertical' />}
            renderView={props => <div {...props} className='sidebar-scroll-flex' />}
          >
            <Grid container className={classes.navContainer} direction='column' wrap='nowrap' justify='space-between'>
              <Grid item />
              <Grid item>
                <List>
                  <BotNavListItem
                    data-test-id='Sidebar.BotNavListItem'
                    botName={currentBotName}
                    botType={currentBot?.botType}
                    callsBotType={currentBot?.callsBotType}
                    botList={botList}
                    location={location}
                    disabled={classes.disabled}
                    language={language}
                    openEditModal={togglePopup}
                    textListItem={classNames(classes.textListItem, !open && classes.textListItemBlock)}
                    listItemClass={classNames(classes.listItemClass, !open && classes.listItemClassBlock)}
                    listIconClass={classNames(classes.listIcon, !open && classes.listIconBlock)}
                    textClass={classNames(classes.listText, !open && classes.listTextBlock)}
                    labelNewClass={{ label: classes.label }}
                  />
                </List>
              </Grid>
              <Grid item>
                <List>
                  <AppHelpNavListItems
                    data-test-id='Sidebar.HelpNavListItem'
                    chatSupport={appConfig.zenflow.chatSupport}
                    toggleFeedbackPopup={this.toggleFeedbackPopup}
                    messages={messages}
                    listItemClass={classNames(classes.listItemClass, !open && classes.listItemClassBlock)}
                    listIconClass={classNames(classes.listIcon, !open && classes.listIconBlock)}
                    textListItemClass={classNames(classes.textListItem, !open && classes.textListItemBlock)}
                    textClass={classNames(classes.listText, !open && classes.listTextBlock)}
                    Popper={classes.Popper}
                    showHelp={appConfig.zenflow.showHelp}
                  />
                  {Boolean(currentUser) && (
                    <ListItem
                      data-test-id='Sidebar.Logout'
                      style={{ padding: '.5rem' }}
                      button
                      className={classNames(
                        classes.listItemClass,
                        !open && classes.listItemClassBlock,
                        'sidebarItem',
                        // 'sidebarPerson',
                        classes.listText,
                        !open && classes.listTextBlock,
                        { active: Boolean(anchorEl) }
                      )}
                      onClick={this.handleMenu}
                      key='Logout'
                    >
                      <ListItemIcon
                        data-test-id='Sidebar.listItemIcon'
                        className={classNames(classes.listIcon, 'sidebarItem', !open && classes.listIconBlock)}
                        style={{ marginBottom: 0 }}
                      >
                        <Icon name='farUserCircle' />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.textListItem}
                        style={{ padding: 0 }}
                        disableTypography
                        primary={t('Sidebar:Profile')}
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </Scrollbars>
          {this.renderMenu()}
          <Link to='/prices' className={classes.hiddenLink} id='hiddenPersonalToTab1' />
        </Grid>
      </Drawer>,
    ];
  }
}

function mapStateToProps(state) {
  return {
    botList: state.BotProjectsReducer.botList,
    fetching: state.BotProjectsReducer.fetching,
    currentProject: state.CurrentProjectsReducer.currentProject,
    currentBot: state.CurrentProjectsReducer.currentBot,
    language: state.CurrentUserReducer.language,
    currentUser: state.CurrentUserReducer.currentUser,
    appConfig: {
      fetching: state.AppConfigReducer.fetching,
      fetched: state.AppConfigReducer.fetched,
      zenflow: state.AppConfigReducer.zenflow,
    },
    cloudDomains: state.AppConfigReducer.cloudDomains,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...BotProjectsActions,
      setCurrentProject,
    },
    dispatch
  ),
});

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Sidebar))
);
